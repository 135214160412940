import { initTooltips } from '~/scripts/lib/utils.js'

export default class Pagelet {
  constructor(callback) {
    this.pageletLoadedCssClass = "pagelet-loaded";
    this.elsToListen = [];
    this.windowHeight = $(window).height();

    this.callback = callback || function () {};
    this.attachPagelets();
    this.addEvents();
  }

  attachPagelets() {
    $(".pagelet").each(function (index, el) {
      // Only load if hasn't already loaded content.
      // Note: this class is also intentionally removed in some sections on page exit so it reloads on restore
      if (!$(el).hasClass(this.pageletLoadedCssClass)) {
        if (this.inScreen(el)) {
          this.getPagelet(el);
        } else {
          this.addToScrollListen(el);
        }
      }
    }.bind(this));
  }

  addEvents() {
    $(window).on('resize', () => {
      this.windowHeight = $(window).height();
    });

    $(window).on('scroll.pagelet', this.onScroll.bind(this));
  }

  onScroll() {
    var scrollY = $(window).scrollTop();

    if (this.elsToListen.length > 0) {
      this.elsToListen.forEach((el, i) => {
        var scrollPosition = $(el).offset().top;

        if (scrollY + (this.windowHeight) >= scrollPosition) {
          // Remove item from array to not endlessly load it.
          this.elsToListen.splice(i, 1);
          this.getPagelet(el);
        }
      });
    }
  }

  removeEvents() {
    this.elsToListen = [];
    $('body, html').off('scroll:pagelet');
  }

  inScreen(el) {
    var windowHeight = $(window).height();
    var scrollPosition = $(window).scrollTop();
    var elPosition = $(el).offset().top;

    return (windowHeight + scrollPosition) >= elPosition;
  }

  addToScrollListen(el) {
    this.elsToListen.push(el);
  }

  getPagelet(pageletDiv) {
    var $pagelet = $(pageletDiv);
    // endpointTarget may contain either endpoint only or with the target element.
    // Currently declared in the format as `url target`.
    var endpointWithTarget = $pagelet.data("endpoint").split(" ");
    // Initializing to select nonce value to set in the pagelet.
    var metatag = document.querySelector('meta[name=csp-nonce]');
    $.ajax({
      url: endpointWithTarget[0],
      headers: {'X-Pagelet-Nonce': metatag.content}
    }).done(function (data) {
      var targetData = data;
      if (endpointWithTarget.length > 1) {
        var targetSelector = endpointWithTarget[1];
        targetData = $(data).find(targetSelector);
      }
      $pagelet.html(targetData);

      $pagelet.addClass(this.pageletLoadedCssClass);
      $pagelet.trigger("pageletLoaded");
      this.callback(pageletDiv);

      initTooltips();
    }.bind(this));
  }
}

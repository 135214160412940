import CompanyAdmin from '~/scripts/lib/CompanyAdmin';
import Highcharts from '~/scripts/integrations/highcharts';
import Pagelet from '~/scripts/lib/Pagelet';

export default class SurveyPeriodsShow {
  constructor() {
    this.addEvents();
    this.initCompletionByDayChart();
  }
  
  addEvents() {
    new CompanyAdmin();
    R.utils.bindCopyText("#survey-link", "#survey-copy-link");
    this.bindTabLinks();
    this.fetchResponses();
    new Pagelet();
  }

  // adapted from views/company_admin/points/redemptions_line_chart.html.erb
  initCompletionByDayChart() {
    let series = $("#survey-responses-line-chart-wrapper .survey-responses-line-chart-holder").data("seriesdata");

    let chartLabels = gon.completion_chart_labels;
    Highcharts.chart('survey-responses-line-chart-wrapper', {
      chart: {
        type: 'spline'
      },
      title: {
        text: chartLabels.title
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: { // don't display the year
          month: '%e. %b',
          year: '%b'
        },
        title: {
          text: chartLabels.x_axis_title
        }
      },
      yAxis: {
        title: {
          text: chartLabels.y_axis_title
        },
        min: 0
      },
      colors: ['#FF0000', '#0000FF'],
      series: series,
      legend: { enabled: false },
      tooltip: {
        // Customize date format to not show time & show year
        // https://api.highcharts.com/highcharts/tooltip.xDateFormat
        xDateFormat: '%Y-%m-%d',
      }
    });
  }

  bindTabLinks() {
    $body.on("click", ".tab-link", function () {
      let tabId = $(this).data("tab-id");
      if (tabId)
        $(`#${tabId}`).tab("show");
    });
  }

  // fetch responses and set into R namespace
  // Results tab will use this data when switching
  // TODO: chart data pagination?
  fetchResponses() {
    let path = gon.responses_path;
    if (!path) return;

    $.getJSON({
      url: path,
      success: function (data) {
        R.ajaxSurveyResponses = data;
      },
      error: function (error) {
        $('.spinner-wrapper').remove();
        R.ajaxSurveyResponsesFetchError = true;
        console.error("Error fetching survey responses", error);
      }
    });
  }
}

/*
 * New pattern: secondary export for a page script file, to be imported by standalone React files included in the same page
 *              before this approach, also tried setting this into window.R, but that was not deterministic & so not very reliable
 **/
export class SurveyPeriodAsyncTabRenderer {

  // to be called from React files when a relevant tab is first shown
  renderTabWithWait(rootElementSelector, renderCallback) {
    let intervalId, intervalIndex = 0;

    if (R.ajaxSurveyResponses || R.ajaxSurveyResponsesFetchError) {
      console.log('rendering immediately');
      this.#renderTabWithIntervalTracking(intervalId, intervalIndex, rootElementSelector, renderCallback);
    } else {
      // poll until ajax data is available
      intervalId = setInterval(() => {
        intervalIndex = this.#renderTabWithIntervalTracking(intervalId, intervalIndex, rootElementSelector, renderCallback);
      }, 300);
    }
  }
  
  // private instance method (supposedly)! from ES2019
  #renderTabWithIntervalTracking(intervalId, intervalIndex, rootElementSelector, renderCallback) {
    let renderingDone = true;
    console.log('intervalIndex:', intervalIndex, 'intervalId:', intervalId)
    if (R.ajaxSurveyResponsesFetchError) {
      clearInterval(intervalId);
      this.#showFetchFailedMessage(rootElementSelector);
    } else if (R.ajaxSurveyResponses) {
      clearInterval(intervalId);
      renderCallback();
    } else if (intervalIndex++ > 3 * 60) { // fail after a minute or so
      clearInterval(intervalId);
      this.#showFetchFailedMessage(rootElementSelector);
    } else {
      renderingDone = false;
    }

    if (renderingDone && intervalId)
      clearInterval(intervalId);

    return intervalIndex;
  }

  // this html replacement also implicitly hides the spinner
  #showFetchFailedMessage(rootElementSelector) {
    const messageHtml = '<div class="alert alert-danger">Failed to load responses</div>';
    $(rootElementSelector).html(messageHtml);
  }
}
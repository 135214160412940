import Highcharts from 'highcharts/es-modules/masters/highcharts.src';
import 'highcharts/es-modules/masters/highcharts-more.src'
import 'highcharts/es-modules/masters/modules/sankey.src';
import 'highcharts/es-modules/masters/modules/solid-gauge.src';
import 'highcharts/es-modules/masters/modules/arc-diagram.src';
import 'highcharts/es-modules/masters/modules/accessibility.src';
import 'highcharts/es-modules/masters/modules/no-data-to-display.src';

window.Highcharts = Highcharts;

export default Highcharts;
